<template>
  <section>
    <div class="ll-topbox">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
                icon="HomeIcon"
                size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Delivery Order') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ $t('Problem Order') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>

    <!-- first floor -->
    <b-card class="card-browser-states ll-fHei ll-card">
      <b-card-body>
        <div class="row">
          <b-input-group class="ll-searchBox col-md-4">
            <b-input-group-prepend>
              <b-form-select
                  v-model="numtype"
                  :options="numList"
                  @input="selnum"
                  class="ll-numSel"
              />
            </b-input-group-prepend>
            <b-form-textarea
                v-model="trackingIDs"
                class="searchInput"
                :placeholder="placeholder"
                no-resize
            />
            <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                :style="{'cursor': loadingList ? 'no-drop' : 'pointer'}"
                :disabled="loadingList"
                @click="searchById">
              <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
              />
            </b-input-group-prepend>
          </b-input-group>

          <div class="col-md-2">
            {{ $t('Problem Shipment Inbound Date (Maximum Range: 31 Days)') }}
            <flat-pickr
                v-model="inboundDate"
                class="form-control ll-fpickr"
                :config="dateEadConfig"
                @on-change="onChangeInb"
            />
          </div>
          <div :title="$t('Customer')" class="col-md-2 ll-filter" v-permission="['Choose Org']"
               :style="[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Customer') }}
            <!--              <span v-if="isAccess2 == false">{{ $t('Customer') }}</span>-->
            <v-select
                v-model.trim="customer"
                :options="customerList"
                label="value"
                @search="queryCustomer"
                @input="getCustomer"
                placeholder="Select Customer"
                multiple
            >
            </v-select>
          </div>
          <div :title="$t('Status')" class="col-md-2 ll-filter"
               :style="[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Handling Status') }}
            <!--              <span v-if="isAccess2 == false">{{ $t('Status') }}</span>-->
            <v-select
                id="status"
                v-model="latestOrderStatus1"
                :options="statusList"
                label="name"
                @input="selStatus"
                placeholder="Select Status"
                multiple
            ></v-select>
          </div>
          <div class="col-md-12 pt-1 ll-filter">
            <div class="ll-btn">
              <b-button variant="secondary" @click="searchById" class="mr-1 btn-icon">
                <feather-icon icon="RefreshCwIcon"/>
              </b-button>
               <b-dropdown
                :text="$t('Batch Operation')"
                variant="info"
                class="ll-batch my-0 ml-0 p-0"
              >
                <b-dropdown-item @click="problemOrderReport">{{$t('Problem Order Report')}}</b-dropdown-item>
              </b-dropdown>
              <b-button
                  variant="secondary"
                  class="mr-1"
                  @click="onClear"
              >
                {{ $t('Clear') }}
              </b-button>
              <b-button variant="primary"
                        @click="searchById">
                {{ $t('Search') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-table
        ref="selectableTable"
        class="position-relative ll-table"
        :items="rows"
        :fields="columns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :striped="true"
        :hover="true"
        selectable
        @row-selected="onRowSelected"
        :select-mode="selectMode"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @sort-changed="onSortChange"
        :sticky-header="true"
        :busy="isBusy"
    >
      <template v-slot:head(selected)="data">
        <b-form-checkbox @input="selectAllRowsData">
        </b-form-checkbox>
      </template>
      <template v-slot:cell(selected)="{rowSelected, index}">
        <b-form-checkbox :checked="rowSelected?true:false" @change="selectRowData(rowSelected, index)">
        </b-form-checkbox>
      </template>
      <template v-slot:cell(package)='row'>

        <feather-icon
            icon="DatabaseIcon"
            size="20"
            v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'ORDER_RECEIVED') "
            color="#409eff"
        />
        <feather-icon
            icon="DatabaseIcon"
            size="20"
            v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'COMP') "
            color="#28c76f"
        />
        <feather-icon
            icon="DatabaseIcon"
            size="20"
            v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'PROD') "
            color="#ff9f43"
        />
        <feather-icon
            icon="DatabaseIcon"
            size="20"
            v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'DIP') "
            color="#ea5455"
        />
        <feather-icon
            icon="BoxIcon"
            size="20"
            v-if="((row.item.orderQty === 1 || row.item.orderQty === null)&& row.item.jobStatus === 'ORDER_RECEIVED') "
            color="#409eff"
        />
        <feather-icon
            icon="BoxIcon"
            size="20"
            v-if="((row.item.orderQty === 1 || row.item.orderQty === null)&& row.item.jobStatus === 'COMP') "
            color="#28c76f"
        />
        <feather-icon
            icon="BoxIcon"
            size="20"
            v-if="((row.item.orderQty === 1 || row.item.orderQty === null) && row.item.jobStatus === 'PROD') "
            color="#ff9f43"
        />
        <feather-icon
            icon="BoxIcon"
            size="20"
            v-if="((row.item.orderQty === 1 || row.item.orderQty === null) && row.item.jobStatus === 'DIP') "
            color="#ea5455"
        />
      </template>
      <template v-slot:cell(customerOrderNo)='row'>
        <span style="color:#ff9f43;cursor: pointer;" @click="onDetail(row.item)">{{ row.item.customerOrderNo }}</span>
      </template>
      <template v-slot:cell(jobStatus)='row'>
        <b-badge
            pill
            class="ll-badge"
            variant="success"
            v-if="row.item.jobStatus === 'COMP'"
        >
          <span>Completed</span>
        </b-badge>
        <b-badge
            pill
            class="ll-badge"
            variant="light-info"
            v-if="row.item.jobStatus === 'ORDER_RECEIVED'"
        >
          <span>Order received</span>
        </b-badge>
        <b-badge
            pill
            class="ll-badge"
            variant="light-warning"
            v-if="row.item.jobStatus === 'PROD'"
        >
          <span>On Warehouse</span>
        </b-badge>
        <b-badge
            pill
            class="ll-badge"
            variant="light-danger"
            v-if="row.item.jobStatus === 'DIP'"
        >
          <span>Delivery in progress</span>
        </b-badge>
      </template>
      <template v-slot:cell(ccc)='row'>
        <span style="color:#ff9f43;cursor: pointer;" @click="onDetail(row.item)">{{ row.item.customerOrderNo }}</span>
      </template>

    </b-table>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
            v-model="size"
            :options="pageOptions"
            class="mx-1"
            @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
            v-model="page"
            :total-rows="totalSize"
            :per-page="size"
            first-number
            last-number
            class="mb-0 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal
      id="modal-details"
      ref="modal-details"
      centered
      title=""
      size="l"
      hide-footer
    >
      <div class="flex-grow-1">
        <b-card class="card-browser-states ll-backg ll-sHei ll-cardHei1">
          <delivery-problem-detail
            :problemInfo="packageInfo"
          ></delivery-problem-detail>
        </b-card>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, VBToggle, BTab, BTabs,
  BPagination, BFormSelect, BAvatar, BFormCheckbox, BFormSelectOption, BModal, VBModal,
  BDropdown, BDropdownItem, BOverlay, BCollapse, BSpinner, BLink,
  BProgress, BProgressBar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import DeliveryProblemDetail from "./components/deliveryProblemDetail.vue";
import {debounce} from "@/libs/fun.js"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permission from '@core/directives/permission/index.js'
import uploadOrder from './uploadOrder.vue'
import axios from 'axios'

export default {
  components: {
    DeliveryProblemDetail,
    BProgress,
    BProgressBar,
    uploadOrder,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCollapse,
    BBadge,
    BButton,
    BLink,
    BSpinner,
    BTable,
    flatPickr,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormSelectOption,
    BModal,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-modal': VBModal,
    permission,
  },
  data() {
    return {
      selectMode: 'multi',
      window: {
        width: 0,
        height: 0
      },
      debugUseList: [{frontEndname: 'Re-Push Order to SF'}, {frontEndname: 'Re-Push Order to EPOD'},],
      disabled: true,
      debugUser: '',
      // statusList: ['REC', 'PROD', 'DIP', 'COMP', 'TRA'],
      statusList: [
        {name: 'Order received', code: 'REC'},
        {name: 'In transit', code: 'TRA'},
        {name: 'On Warehouse', code: 'PROD'},
        {name: 'Delivery in progress', code: 'DIP'},
        {name: 'Completed', code: 'COMP'},
      ],
      trackingIDs: '',
      dateConfig: {
        dateFormat: 'Y-m-d H:i:ss',
        mode: 'range',
        defaultDate: [(new Date).setDate((new Date).getDate() - 2), new Date()],
        // enableTime: true, // 启用时间选择器
      },
      dateEadConfig: {
        dateFormat: 'Y-m-d',
        mode: 'range',
        defaultDate: null,
        // enableTime: true, // 启用时间选择器
      },
      condition: {
        page: '1',
        size: '100',
        shipmentDateFrom: null,
        shipmentDateTo: null,
        serviceParty: null,
        orderBy: 'createdAt',
        sequence: 'desc',
        tags: null,
        latestOrderStatus: null,
        trackingIDs: null,
        // orgId: 'org006',
        orgName: null,
        deliveryType: null,
        orderIds: null,
        receiverPhone: null,
        isAmazon: false,
        customerType: null,
        sortCode: null,
        udf1: null,
        eadDateFrom: null,
        eadDateTo: null,
        orgIds: null,
        inboundDateFrom: null,
        inboundDateTo: null,
      },
      time: '',
      eadDate: '',
      inboundDate: '',
      isSelectingTime: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200, 500],
      page: null,
      size: 100,
      totalSize: null,
      totalPage: null,
      tableHeaders: [
        {label: '', prop: "package", width: "80"},
        {label: this.$t('Order date'), prop: "createdAt", width: "200"},
        {label: this.$t('Customer Name'), prop: "displayName", width: "180"},
        {label: this.$t('Order No'), prop: "customerOrderNo", width: "auto"},
        {label: this.$t('Status'), prop: "jobStatus", width: "200"},
        {label: this.$t('Tracking Number'), prop: "tkID", width: "auto"},
        {label: this.$t('Tag'), prop: "tag", width: "auto"},
        {label: this.$t('UDF'), prop: "udf1", width: "auto"},
        {label: this.$t('Est. Delivery'), prop: "pkEadDate", width: "150"},
        {label: this.$t('Package qty'), prop: "orderQty", width: "150"},
        {label: this.$t('Label'), prop: "label", width: "80"},
      ],
      orderStatus: '',
      orderStatusCode: {
        rec: 0, // Order received
        prod: 0, // On Warehouse
        dip: 0, // Delivery in progress
        comp: 0, // Completed
      },
      tkid: '',
      orderId: '',
      selected: [],
      color: 'info',
      color1: 'primary',
      color2: 'danger',
      color3: 'warning',
      tagsList: [],
      sPartyList: [],
      serviceParty: '',
      windowWidth: document.documentElement.clientWidth,  //屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //屏幕高度
      height: document.documentElement.clientHeight * 0.67,
      hei: (document.documentElement.clientHeight * 0.67 - 46) / 10,
      heiVal: null,
      hei1: document.documentElement.clientHeight * 0.12,
      numList: ['Reference Number', 'Tracking Number', '3RD Tracking Number'],
      numtype: 'Reference Number',
      sonList: [],
      filterBg: {
        '--background': '#fff'
      },
      customerList: [],
      customer: '',
      typeList: [
        {key: 'HD', des: 'Home Delivery'},
        {key: 'SP', des: 'Self Pick'},
        {key: 'SD', des: 'Same Day Delivery'}
      ],
      pkg: false,
      type: null,
      placeholder: 'Enter Order Number to search',
      searchType: 'Reference Number',
      nums: 1,
      idx: null,
      tags: null,
      latestOrderStatus1: '',
      ischeck: false,
      isAllCheck: false,
      isAllCheck1: false,
      check1: true,
      check2: false,
      arrBox: [],
      loadingList: false,
      selectedItem: null,
      jobDetails1: null,
      jobTags: null,
      jobDetails2: null,
      jobDetails3: null,
      jobDetails4: null,
      packageInfo: null,
      jobDetails5: null,
      domObj: null,
      isTbox: true,
      cusTypeList: [],
      cusType: '',
      isBusy: true,
      baseURL: axios.defaults.baseURL,
      isAccess: true,
      isAccess2: true,
      areaList: [],
      area: null,
      area1: null,
      routeCodeList: [],
      sortCode: null,
      routeCodeList1: [],
      sortCode1: null,
      isBatchSortCode: false,
      sortCodeRows: [],
      sortCodeColumns: [
        {key: 'createdAt', label: this.$t('Order date')},
        {key: 'customerName', label: this.$t('Customer')},
        {key: 'tkID', label: this.$t('Tracking Number')},
        {key: 'cneeAddressStr', label: this.$t('Address')},
        {key: 'sortCode', label: this.$t('Sort Code'), class: 'll-sortCodeBox'},
      ],
      ishd: true,
      issp: false,
      vendorList: [],
      vendor: null,
      locCodeList: [],
      locName: null,
      isLocCode: false,
      isLocName: false,
      isRcode: false,
      paramsList: null,
      paramsList1: null,
      isBatchUpdate: false,
      isDebugUse: false,
      items: [],
      fields: [
        {key: 'createdAt', label: this.$t('Upload time')},
        {key: 'operator', label: this.$t('Operator')},
        {key: 'taskName', label: this.$t('Upload type')},
        {key: 'progressNow', label: this.$t('Status'), class: 'll-progress'},
        {key: 'progressMax', label: this.$t('Total orders')},
        {key: 'id', label: this.$t('Error orders')},
        // { key: 'input', label: this.$t('Download source file') },
        // { key: 'output', label: this.$t('Download Error message') },
      ],
      test: '',
      tkIds: [],
      orgIds: [],
      rssList: [],
      selectAll: false,
      scSortCode: null,
      scSortCodeList: [],
      rows: [],
      columns: [
        {key: 'selected', label: 'selected'},
        {key: 'orgID', label: this.$t('Customer No'), width: "100"},
        {key: 'customerName', label: this.$t('Shipper Name'), width: "180"},
        {key: 'customerOrderNo', label: this.$t('Reference Number'), width: "auto"},
        {key: 'tkID', label: this.$t('Tracking Number'), width: "auto"},
        {key: 'latestOrderStatus', label: this.$t('Tracking Status'), width: "200"},
        {key: 'callResult', label: this.$t('Call Result'), width: "200"},
        {key: 'latestCallDate', label: this.$t('Latest Call Date'), width: "200"},
        {key: 'problemShipmentOutboundDate', label: this.$t('Problem Shipment Outbound Date'), width: "200"},

        {key: 'numberOfCallAttempts', label: this.$t('No. of Call Attempt'), width: "200"},

      ],
    }
  },
  computed: {},
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.timer);
  },
  mounted() {
    this.getList()
    this.querySparty()
    this.queryCustomer()
    this.getAccess()
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        this.windowHeight = window.fullHeight
        this.windowWidth = window.fullWidth
      })()
    }
  },
  beforeDestroy() {
    if (!this.domObj) return
    if (this.domObj.attachEvent) {
      this.domObj.detachEvent('onmousewheel', this.mouseScroll)
    }
    if (this.domObj.addEventListener) {
      this.domObj.removeEventListener('DOMMouseScroll', this.mouseScroll, false)
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    mouseScroll(event) { // google 浏览器下
      // 向上滚 event.wheelDelta = 120
      // 向下滚 event.wheelDelta = -120
      if (event.wheelDelta > 0) {
        this.isTbox = true
      } else {
        this.isTbox = false
      }
    },
    // 默认日期格式转换
    dateFormats() {
      const fromDate = new Date()
      let datetime = fromDate.getFullYear() + "-" +
          (fromDate.getMonth() > 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
          + (fromDate.getDate() > 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
          + ' ' + '23:59:59'

      const toDate = new Date((new Date).setDate((new Date).getDate() - 2))
      let datetime1 = toDate.getFullYear() + "-" +
          (toDate.getMonth() > 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
          + (toDate.getDate() > 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
          + ' ' + '00:00:00'

      this.condition.inboundDateFrom = datetime1
      this.condition.inboundDateTo = datetime
    },
    // 查询列表
    queryList() {
      this.loadingList = true
      this.isBusy = true
      this.$http.post('/jobDeliveryProblem/search', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.getStatusCount()
        this.loadingList = false
        this.isBusy = false
        let tkIDs = info.content.map(item => {
          return item.tkID
        })
        console.info(info.content)
      }).catch(error => console.log(error))
    },
    // 首次查询
    getList() {
      this.dateConfig.dateFormat = 'Y-m-d'
      this.dateFormats()
      this.queryList()
    },
    // 根据日期查询
    onChange(selectedDates, dateStr, instance) {
      if (selectedDates.length == 1) {
        let later = new Date(selectedDates[0].getTime() + 86400000 * 31)
        instance.config.minDate = selectedDates[0].toUTCString()
        instance.config.maxDate = later.toUTCString()
      } else if (selectedDates.length == 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = undefined
        this.condition.eadDateFrom = null
        this.condition.eadDateTo = null
        this.condition.inboundDateFrom = null
        this.condition.inboundDateTo = null
        this.eadDate = ""
        this.queryList()
      }
    },
    onChangeInb(selectedDates, dateStr, instance) {
      if (selectedDates.length == 1) {
        let later = new Date(selectedDates[0].getTime() + 86400000 * 31)
        instance.config.minDate = selectedDates[0].toUTCString()
        instance.config.maxDate = later.toUTCString()
      } else if (selectedDates.length == 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = undefined
        this.condition.inboundDateFrom = this.formatDate(selectedDates[0]) + ' 00:00:00'
        this.condition.inboundDateTo = this.formatDate(selectedDates[1]) + ' 23:59:59'
        this.condition.eadDateFrom = null
        this.condition.eadDateTo = null
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        this.time = ""
        this.queryList()
      }
    },
    // 根据ID查询
    searchById() {
      this.selected = ''
      if (this.trackingIDs.trim() !== '') {
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        this.condition.eadDateFrom = null
        this.condition.eadDateTo = null
        this.condition.page = '1'
        this.time = null
        this.eadDate = null

        this.condition.orderIds = null
        this.condition.trackingIDs = null
        this.condition.udf1 = null
        this.condition.refTkIDs = null
        if (this.searchType === 'Tracking Number') {
          this.condition.trackingIDs = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
          this.condition.orderIds = null
          this.condition.udf1 = null
        } else if (this.searchType === 'Reference Number') {
          this.condition.orderIds = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
        } else if (this.searchType === 'User Define Value') {
          this.condition.udf1 = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
        } else if (this.searchType === '3RD Tracking Number') {
          this.condition.refTkIDs = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
        }
      } else {
        if (this.condition.inboundDateFrom !== '') {

        } else {
          this.dateFormats()
        }
        this.condition.trackingIDs = null
        this.condition.orderIds = null
        this.condition.udf1 = null
        this.condition.refTkIDs = null
      }
      this.queryList()
    },
    onRowSelected(items) {
      this.selected = items
      this.sortCodeRows = items
      const arr = []
      const arr1= []
      const arr2= []
      const arr3 = []
      items.map(i=>{
        const obj = {}
        obj.customerOrderNo = i.customerOrderNo
        obj.tkID = i.tkID
        obj.labelUrl= (i.updateLabelUrl!== null) ? i.updateLabelUrl : i.labelUrl
        obj.labelUrl= (i.updateLabelUrl!== null) ? i.updateLabelUrl : i.labelUrl
        obj.orderQty = i.orderQty
        arr.push(obj)
        arr1.push(i.id)
        arr2.push(i.tkID)
        arr3.push(i.customerOrderNo)
        const obj1= {}
        obj1.cneeAddressStr = i.cneeAddressStr
        obj1.cneeCity = i.cneeCity
        obj1.cneeState = i.cneeState
        obj1.updAddress = i.updAddress
        obj1.cneeCountry = i.cneeCountry
        obj1.tkID = i.tkID
        this.rssList.push(obj1)
      })
      this.arrBox = arr
      this.paramsList1 = arr1
      this.tkIds = arr2
      this.orgIds = arr3
    },
    selectRowData(rowSelected, index){
      if(rowSelected == false){
        this.$refs.selectableTable.selectRow(index)
      }else{
        this.$refs.selectableTable.unselectRow(index)
      }
    },
    selectAllRowsData(val) {
      this.selectAll = val ? true : false
      if (val == true) {
        this.$refs.selectableTable.selectAllRows()
      }
      if(val == false){
        this.$refs.selectableTable.clearSelected()
      }
    },
    handleChangePage(page) {
      this.condition.page = page.toString()
      this.nums = 1
      this.queryList()
    },
    handlePageChange(active) {
      this.condition.page = '1'
      this.condition.size = active
      this.queryList()
    },
    onClear() {
      this.latestOrderStatus1 = null
      this.condition.tags = null
      this.tags = null
      this.condition.orgName = null
      this.condition.orgIds = null
      this.pkg = null
      this.type = null
      this.condition.deliveryType = null
      this.cusType = null
      this.customer = null
      this.area = null
      this.sortCode = null
      this.dateConfig.dateFormat = 'Y-m-d'
      this.inboundDate = null
      this.dateFormats()
      this.queryList()
    },
    // 点击查看详情
    onDetail(obj) {
      if (obj) {
        // this.tkid = obj.tkID
        // this.orderId = obj.orderID
        this.selected = obj.tkID
        this.selectedItem = obj
        this.getDetail(obj.problemParcelID)
        this.searchJobDeliveryProblem(obj.tkID)
        this.$refs['modal-details'].show()
      } else {
        this.tkid = ""
        this.orderId = ""
        this.selected = ""
        this.selectedItem = null
      }
    },
    getDetail(oId) {
      this.$http.get('/jobDeliveryProblem/getProblemDetail', {params: {problemParcelID: oId}}).then(res => {
        this.packageInfo = res.data.data
      })
    },
    searchJobDeliveryProblem(tkid) {
      console.log(tkid)
      const para = {}
      para.page = '1'
      para.size = '10'
      para.isAmazon = false
      para.trackingIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobDeliveryProblem/search', para).then(res => {
        if (res.data.data.content.length !== 0) {
          this.jobDetails2 = res.data.data.content[0]
        }
      })
    },
    selStatus(t) {
      const arr = []
      t.map(item => {
        arr.push(item.code)
      })
      this.condition.latestOrderStatus = arr
    },
    // query service party
    querySparty() {
      // this.$http.get('jobkes/getServicePartyList').then(res => {
      //   this.sPartyList = res.data.data
      // })

    },
    queryCustomer(d) {
      let condition = d ? d : null
      this.$http.get('/admin/settings/organization/getCacheOrganization?condition=' + condition).then(res => {
        this.customerList = res.data.data
      })

    },
    getCustomer(d) {
      const arr = []
      d.map(item => {
        arr.push(item.key)
      })
      this.condition.orgIds = arr
    },
    // 查询status次数
    getStatusCount1() {
      this.$http.post('jobkes/getTotalStatusList', this.condition).then(res => {
        const result = res.data.data
        this.orderStatusCode.comp = (result.COMP) !== 'undefined' ? result.COMP : 0
        this.orderStatusCode.rec = (result.REC) !== 'undefined' ? result.REC : 0
        this.orderStatusCode.dip = (result.DIP) !== 'undefined' ? result.DIP : 0
        this.orderStatusCode.prod = (result.PROD) !== 'undefined' ? result.PROD : 0
      })
    },
    getStatusCount() {
      if (this.trackingIDs.trim() !== '') {
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        // this.getStatusCount1()
      } else {
        // this.getStatusCount1()
      }
    },
    selnum(d) {
      this.numtype = d
      if (d === 'Tracking Number') {
        this.placeholder = 'Enter Tracking Number to search'
        this.searchType = d
      } else if (d === 'Reference Number') {
        this.placeholder = 'Enter Order Number to search'
        this.searchType = d
      } else if (d == '3RD Tracking Number') {
        this.placeholder = 'Enter 3RD Tracking Number to search'
        this.searchType = d
      }
    },
    selType(d) {
      if (d) {
        this.condition.deliveryType = d.key
        this.type = `${d.des}`
      }
      console.log(d)

    },
    getTypeText(d) {
      if (d) {
        return d.des
      }
    },
     problemOrderReport(){
      if (this.orgIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      let obj = {}
      if (this.selectAll) {
        obj.from = '2022-03-07 00:00:00' // 日期不能为空，假数据
        obj.to = '2022-03-07 00:00:00'
        obj.reportType = 'ProblemOrderReport'
        obj.orderStatus = null
        obj.isAmazon = false
        obj.all = true
        obj.jobKesByPage = this.condition
      }else{
        obj.from = this.condition.inboundDateFrom
        obj.to = this.condition.inboundDateTo
        obj.reportType = 'ProblemOrderReport'
        obj.orderStatus = null
        obj.isAmazon = false
        obj.all = false
        obj.orgIds = this.orgIds
      }
      this.$http.post('/exportReport/exportProblemOrderList',obj).then(res=> {
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'batch-update' })
        }
      })
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    getAccess() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      if (userData.organizationName == 'KEC') {
        this.isAccess = true
        this.isAccess2 = true
      } else {
        this.isAccess = false
        this.isAccess2 = false
      }
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
  },
}
</script>
<style scoped>


.ll-fail {
  margin: auto;
}

.ll-switch {
  width: 100px;
}

.ll-cardHei1 > .card-body {
  padding: 0;
}

.ll-tBox {
  /* position: fixed; */
  height: 46px;
  z-index: 9;
  /* width: 400px; */
  background-color: #fff;
  padding-left: 0.5rem;
}

.ll-cbox {
  margin-top: 10px;
  overflow-y: auto;
  border-top: 1px solid #d9d9d9;
}

.ll-batch {
  /* position: absolute;
  right: 14rem;
  z-index: 9;
  top: 6rem; */
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0;
}

.ll-badge {
  /* padding: 2px; */
  margin-right: 2px;
  font-size: 14px;
}

.ll-searchBtn div {
  background-color: #ed6d01;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ll-searchBtn div:active {
  opacity: 0.8;
}

.ll-fHei {
  /*height: 124px;*/
  height: var(--height);
  margin-bottom: 10px;
}

.icon {
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.ll-conBox {
  display: flex;
  align-items: center;
}

.ll-box {
  margin-left: 10px;
}

.ll-box span {
  display: block;
  text-align: center;
}

.ll-newbox {
  text-align: center;
}

.ll-newbox .ll-tit {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
}

.ll-fHei > .card-body {
  display: flex;
  align-items: center;
}

.ll-cardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ll-card > .card-body {
  padding: 10px 5px;
  border-radius: 5px;
}

.ll-fpickr {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 7px;
}

.ll-tabs .nav-pills .nav-link.active {
  background-color: unset;
  color: #5e5873;
  box-shadow: none;
  border: none;
  border-bottom: 3px solid #ed6d01;
  border-radius: 0px;
}

.ll-tabs .nav-item {
  font-weight: bold;
}

.ll-btn {
  bottom: 10px;
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
}

.ll-tkid {
  color: #161d31;
}

.ll-cardList {
  margin-bottom: 5px;
}

.ll-cardList .card-header {
  padding: 0.5rem;
}

.ll-cardList .ll-cardBody {
  padding: 0.5rem;
}

.ll-cardHei {
  /* height: 100%; */
  overflow-y: auto;
}

.ll-page {
  position: absolute;
  bottom: -10px;
  /* left: 15%; */
}

.ll-filter {
  padding: 0 1rem 0 1rem;
}

.ll-filter .v-select {
  margin-top: 7px;
}

.ll-cardActive {
  background: #f6f6f6;
}

.ll-backg {
  /* background: #f6f6f6; */
  height: 95%;
}

.ll-0 + div {
  display: none;
}

.ll-rowbox {
  width: 100%;
  margin-left: 0;
}

.ll-rowbox .ll-col1 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col2 {
  text-align: left;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
}

.ll-rowbox .ll-col3 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col4 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col5 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col6 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-custom {
  padding: 0 10px;
}

.ll-numSel {
  height: auto;
}

.ll-pg {
  width: 30%;
}

.ll-outline > div {
  background: var(--background);
  margin-right: 1px;
}

.ll-hover {
  cursor: pointer;
}

/* .ll-hover:hover{
  color: #ed6d01;
} */
.ll-fontbold {
  font-weight: bold;
}

</style>
<style>
[dir] .table-hover tbody tr {
  font-size: 14px !important;
}

[dir] .table td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

[dir] .table.b-table > tbody .b-table-row-selected.table-active td {
  background-color: unset;
  background: unset;
}

[dir] .table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  background-color: unset;
  background: unset;
}

.ll-eltable th {
  padding: 0;
}

.ll-sortCodeBox {
  width: 20%;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  vertical-align: middle;
}

.ll-tabs .nav-item {
  font-weight: bold;
}

.ll-collapse .card {
  box-shadow: none;
}

.ll-text {
  margin: auto;
}

[dir] .custom-select {
  border-radius: 0;
}

[dir] .input-group:not(.bootstrap-touchspin):focus-within .form-control,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .input-group-text,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .custom-select {
  border-color: #ed6d01;
  box-shadow: none;
}

.ll-row {
  flex-wrap: nowrap;
}

.action-button {
  margin: 10px;
}

.ll-rowbox.order-detail {
  padding: 10px;
  border-radius: 0.428rem;
  font-size: 14px;
  margin-top: 10px;
}

.card.card-browser-states.ll-card.ll-cardList.ll-hover.order-detail.bg-white {
  box-shadow: none;
  font-size: 12px;
  border-radius: 5px;
  margin: 10px 0px;
}

.card.card-browser-states.ll-card.ll-cardList.ll-hover.order-detail.bg-white .card-body:hover {
  background: #eeeeee;
}

.modal-lg {
  max-width: 90%;
}

/* .modal-content {
  height: 90vh;
} */
.ll-tkid.column-title {
  font-weight: bold;
  font-size: 14px;
}

td.small-cell {
  padding-left: 40px !important;
  width: 10%;
}

td.extra-small-cell {
  padding-left: 13px !important;
  width: 5%;
}

td.small-cell span {
  padding-left: 7px;
}

.ll-wrapper {
  height: 25vh !important;
}

.ll-tableHei {
  height: 25vh !important;
}

.modal-body {
  padding: 5px !important;
  overflow-y: scroll !important;
}
</style>
