<template>
  <section>
    <h5>Order Detail & Call Log</h5>
    <table class="mb-2">
      <tr>
        <td style="width: 40%">Tracking No.</td>
        <td>{{ list.tkID }}</td>
      </tr>
      <tr>
        <td class="">Reference No.</td>
        <td>{{ list.customerOrderNo }}</td>
      </tr>
      <tr>
        <td class="">Consignee Name</td>
        <td>{{ list.cneeName }}</td>
      </tr>
      <tr>
        <td class="">Consignee Contact</td>
        <td>{{ list.cneePhone }}</td>
      </tr>
      <tr>
        <td class="">Consignee Address</td>
        <td>{{ list.cneeAddress }}</td>
      </tr>
      <tr>
        <td class="">Order Creation Date</td>
        <td>{{ list.pkShippingDate }}</td>
      </tr>
      <tr>
        <td class="">Problem Shipment Inbound Date
        </td>
        <td>{{ list.problemWarehouseInboundDate }}</td>
      </tr>
      <tr>
        <td class="">Problem Shipment Outbound Date
        </td>
        <td>{{ list.problemWarehouseOutboundDate }}</td>
      </tr>
      <tr>
        <td class="">Problem Shipment Reason
        </td>
        <td>{{ list.problemShipmentReason }}</td>
      </tr>
      <tr>
        <td class="">Problem Shipment Location
        </td>
        <td>{{ list.problemWarehouseLocation }}</td>
      </tr>
      <tr>
        <td class="">Damage Parcel
        </td>
        <td>{{ list.isDamaged?"Yes":"No" }}</td>
      </tr>
    </table>
    <h5>Create Call Log</h5>
    <b-row>
      <b-col cols="4">
        Call Result
      </b-col>
      <b-col cols="8">
        <b-form-radio-group
            class="form-group"
            id="CallStatus"
            v-model="logForm.callStatus"
            :options="options"
            name="selected"></b-form-radio-group>
      </b-col>
      <b-col cols="4">
        Schedule Delivery Date
      </b-col>
      <b-col cols="8">
        <b-form-datepicker id="scheduleDeliveryDate" v-model="logForm.scheduleDeliveryDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" reset-button>
        </b-form-datepicker>
      </b-col>
      <b-col cols="4">
        Fail Reason
      </b-col>
      <b-col cols="8">
        <b-form-input type="text" id="failReason" v-model="logForm.failReason"></b-form-input>
      </b-col>
      <b-col cols="4">
        Call Remark
      </b-col>
      <b-col cols="8">
        <b-form-input type="text" id="remark" v-model="logForm.remark"></b-form-input>
      </b-col>
      <b-col cols="12">
        <b-button @click="createCallLog" variant="primary">Create Call Log</b-button>
      </b-col>
    </b-row>
    <br>
    <h5>Call Logs</h5>
    <table>
      <tr>
        <td>No.</td>
        <td>Log Date Time</td>
        <td>Caller</td>
        <td>Schedule Delivery Date</td>
        <td>Fail Reason</td>
        <td>Remark</td>
      </tr>
      <tr v-for="(column,index) in this.callLog" :key="index">
        <td>{{ column.no }}</td>
        <td>{{ column.logDateTime }}</td>
        <td>{{ column.caller }}</td>
        <td>{{ column.scheduleDeliveryDate ? column.scheduleDeliveryDate : "-" }}</td>
        <td>{{ column.failReason ? column.failReason : "-" }}</td>
        <td>{{ column.remark ? column.remark : "-" }}</td>
      </tr>
    </table>

  </section>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BRow, BCol, BImg,BButton
} from 'bootstrap-vue'
import {Swiper, SwiperSlide} from "vue-awesome-swiper"
import "swiper/css/swiper.css"

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BImg,
    Swiper,
    SwiperSlide,
  },
  props: {
    problemInfo: Object
  },
  watch: {
    problemInfo(val) {
      this.getInfo(val)
    }
  },
  data() {
    return {
      list: [],
      callLog: [],
      swiperOptions: {
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      options: [
        {text: 'Success', value: 'Success'},
        {text: 'Fail', value: 'Fail'},
        {text: 'Return to shipper', value: 'Return to shipper'},
      ],
      logForm: {
        problemParcelID: '',
        callStatus: '',
        scheduleDeliveryDate: '',
        failReason: '',
        remark: '',
      }
    }
  },
  methods: {
    getInfo(val) {
      this.list = val
      console.log(this.list)
      this.callLog = JSON.parse(this.list.callLog)
      console.log(this.callLog)
      this.logForm.problemParcelID = val.problemParcelID;
    },
    createCallLog() {
      // check if the form is filled correctly, if call result is fail, fail reason must be filled, if call result is success or return to shipper, schedule delivery date must be filled
      if (this.logForm.callStatus === 'Fail' && this.logForm.failReason === '') {
        alert('Please fill in the fail reason')
        return
      }
      if ((this.logForm.callStatus === 'Success' || this.logForm.callStatus === 'Return to shipper') && this.logForm.scheduleDeliveryDate === '') {
        alert('Please fill in the schedule delivery date')
        return
      }
      this.$http.post('/jobDeliveryProblem/createCallLog', this.logForm).then(res=>{
        console.log(res)
        this.callLog = JSON.parse(res.data.data.jobDeliveryProblem.callLog)
      }).catch(err=>{
        console.log(err)

      });
    }
  }
}
</script>
<style scoped>

table {
  width: 100%;
  color: black;
}

td {
  border: lightgrey solid 3px;
  padding: 5px;
}

.section {
  overflow: auto;
}
</style>